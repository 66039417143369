import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Trans, useTranslation } from "react-i18next";
import { Link, Route, Routes } from "react-router-dom";

import "normalize.css";

import "./App.css";
import apple from "./assets/apple.png";
import fansLogo from "./assets/fans-logo.svg";
import google from "./assets/google.png";
import { Button } from "./components/Button";
import { LanguageSelector } from "./components/LanguageSelector";
import config from "./config";
import { AppProvider } from "./contexts/app";
import { useApp } from "./hooks/app";
import { NotFound } from "./pages/Common/NotFound";
import { Event } from "./pages/Event";
import { Home } from "./pages/Home";
import { Notice } from "./pages/Notice";
import { Privacy } from "./pages/Privacy";
import { SettingsNotice } from "./pages/SettingsNotice";
import { Terms } from "./pages/Terms";
import { getDeviceOs } from "./utils/helpers";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/notice/:noticeId" element={<SettingsNotice />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/privacy" element={<Privacy />} />

      <Route path="/community/:communitySlug/">
        <Route path="notice/:noticeId" element={<Notice />} />
        <Route path="event/:eventId" element={<Event />} />
      </Route>

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

const Layout = ({ children }) => {
  const [gradientOpacity, setGradientOpacity] = useState(1);
  const scrollableElementRef = useRef(null);
  const { t } = useTranslation();
  const { primaryColor, buttonTextColor, hideGradientOnScroll } = useApp();

  useEffect(() => {
    const handleScroll = () => {
      if (!hideGradientOnScroll) {
        return;
      }

      const element = scrollableElementRef.current;
      if (element) {
        const scrollHeight = element.scrollHeight;
        const scrollTop = element.scrollTop;
        const clientHeight = element.clientHeight;
        const scrollBottom = scrollHeight - scrollTop - clientHeight;

        const newOpacity = scrollBottom > 200 ? 1 : scrollBottom / 200;

        setGradientOpacity(newOpacity);
      }
    };

    const scrollableElement = scrollableElementRef.current;
    if (scrollableElement) {
      scrollableElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollableElement) {
        scrollableElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [hideGradientOnScroll]);

  // ==

  const appLink = config.appLink[getDeviceOs() ?? "ios"];

  return (
    <div className="app">
      <div className="sider">
        <div className="logo">
          <img src={fansLogo} alt="Fans Logo" />
        </div>
        <h4 className="subtitle">
          <Trans i18nKey="web.page.App.subtitle" />
        </h4>
        <p className="description">
          <Trans i18nKey="web.page.App.description" />
        </p>
        <p>
          <Link to={config.appLink.ios} target="_blank">
            <img src={apple} className="app-store" />
          </Link>
          <Link to={config.appLink.android} target="_blank">
            <img src={google} className="app-store" />
          </Link>
        </p>
        <LanguageSelector />
      </div>
      <div className="content">
        <div className="content-scroll" ref={scrollableElementRef}>
          {children}
        </div>
        <div
          className="gradient"
          style={{
            opacity: gradientOpacity,
          }}
        />
        <Link to={appLink} target="_blank">
          <Button
            className="cta-button"
            backgroundColor={primaryColor}
            textColor={buttonTextColor}
          >
            {t("web.page.App.cta")}
          </Button>
        </Link>
      </div>
    </div>
  );
};

const App = () => {
  return (
    <AppProvider>
      <Helmet>
        <title>{config.defaultMetaTitle}</title>
        <meta name="description" content={config.defaultMetaDescription} />
        <meta property="og:title" content={config.defaultMetaTitle} />
        <meta property="og:description" content={config.defaultMetaDescription} />
        <meta property="og:image" content={config.defaultOgImage} />
      </Helmet>
      <Layout>
        <AppRoutes />
      </Layout>
    </AppProvider>
  );
};

export default App;
